import { React, useEffect, useState } from "react";
import { STUDENT_ACADEMIC_COURSE_LISTING } from "../../../apis/apis";
import SisLoader from "../../../widgets/loader";
import AcademicsTransferredCourseTable from "../Tables/academics_transferred_course_table";
import { Table } from "antd";

const ViewStudentAcademics = () => {
  const [loader, set_loader] = useState(true);
  const [selected_concentration, set_selected_concentration] = useState("");
  const [my_academic_details, set_my_academic_details] = useState("");
  const [attendances_summary, set_attendances_summary] = useState("");
  const [programme_progress, set_programme_progress] = useState("");
  const [current_course, set_current_course] = useState("");
  const [semester_id, set_semster_id] = useState("");
  const [calendar_id, set_calendar_id] = useState("");
  const [semester_name, set_semester_name] = useState("");
  const [calendar, set_calendar] = useState("");
  const [program, set_program] = useState("");
  const [basic_information, set_basic_information] = useState("");
  const [semester_registration, set_semester_registration] = useState("");
  const [
    students_programmes_registrations,
    set_students_programmes_registrations,
  ] = useState("");
  const [transferred_course_list, set_transferred_course_list] = useState([]);
  const [payment_plan, set_payment_plan] = useState([]);
  const [payment_status, set_payment_status] = useState("");
  const [student_final_bill, set_student_final_bill] = useState({});
  const [upfront_amount_payment_status, set_upfront_amount_payment_status] =
    useState("");
  const [units_selected, set_units_selected] = useState("");
  const [general_eduction, set_general_eduction] = useState([]);
  const [progrma_concentration, set_progrma_concentration] = useState([]);

  const columns = [
    {
      title: "Courses",
      render: (text, record) => {
        return (
          <>
            {record?.title}{" "}
            <span
              style={{
                fontSize: "10px",
                backgroundColor: "red",
                color: "#fff",
                padding: "1px 7px",
                borderRadius: "5px",
              }}
            >
              SEC-
              {record?.course_semester_registration?.section_id ? "I" : "II"}
            </span>
          </>
        );
      },
      sorter: (a, b) => a.course_title.localeCompare(b.course_title),
    },
    {
      title: "Courses Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Total Credits",
      dataIndex: "units",
      key: "units",
    },
    {
      title: "Attempted Credits",
      render: (text, record) => {
        return (
          <>
            {record?.course_semester_registration?.given_credits > 1
              ? record?.course_semester_registration?.given_credits
              : "No Attempted Credits"}
          </>
        );
      },
    },
    {
      title: "Room",
      render: (text, record) => {
        return (
          <>
            {record?.rooms?.room_name} ({record?.rooms?.room_number})
          </>
        );
      },
    },
    {
      title: "Timing",
      render: (text, record) => {
        var timingData = record?.timing ? JSON.parse(record.timing) : null;
        return <>{timingData && get_timing(record, timingData)}</>;
      },
    },
    {
      title: "Faculty",
      render: (text, record) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {record?.faculty?.honorific} {record?.faculty?.name}
            </span>{" "}
          </>
        );
      },
    },
    {
      title: "Course Status",
      render: (text, record) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {record?.course_semester_registration?.course_status == null && (
                <>Not Registered</>
              )}
              {record?.course_semester_registration?.course_status == 1 && (
                <>Not Registered</>
              )}
              {record?.course_semester_registration?.course_status == 2 && (
                <>Registered</>
              )}
              {record?.course_semester_registration?.course_status === 3 && (
                <>Completed</>
              )}
              {record?.course_semester_registration?.course_status === 4 && (
                <>Withdrawn</>
              )}
              {record?.course_semester_registration?.course_status === 5 && (
                <>Graded</>
              )}
              {record?.course_semester_registration?.course_status === 6 && (
                <>Repeated</>
              )}
              {record?.course_semester_registration?.course_status === 7 && (
                <>Partial Completed</>
              )}
            </span>{" "}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING();
  }, []);

  const VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", USER_DATA.id);
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("semester_id", USER_DATA.current_semester);
    FORM_DATA.append("acad_calender_id", USER_DATA.calendar_id);
    const VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE =
      await STUDENT_ACADEMIC_COURSE_LISTING(FORM_DATA);
    if (
      VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
        ?.status
    ) {
      set_programme_progress(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.programme_progress
      );
      set_attendances_summary(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.attendances_summary
      );
      set_selected_concentration(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.selected_concentration
      );

      if (
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.progrma_concentration.length > 0
      ) {
        var con_array =
          VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.progrma_concentration.filter(
            (item) =>
              item?.course_semester_registration != null &&
              item?.course_semester_registration?.course_status != 3 &&
              item?.course_semester_registration?.course_status != 4 &&
              item?.course_semester_registration?.course_status != 5 &&
              item?.course_semester_registration?.course_status != 6 &&
              item?.course_semester_registration?.course_status != 7 &&
              item?.course_semester_registration?.course_status != 8 &&
              !item?.course_semester_registration?.request_type
          );
        set_progrma_concentration(con_array);
      }

      if (
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.general_eduction.length > 0
      ) {
        var con_array =
          VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.general_eduction.filter(
            (item) =>
              item?.course_semester_registration != null &&
              item?.course_semester_registration?.course_status != 3 &&
              item?.course_semester_registration?.course_status != 4 &&
              item?.course_semester_registration?.course_status != 5 &&
              item?.course_semester_registration?.course_status != 6 &&
              item?.course_semester_registration?.course_status != 7 &&
              item?.course_semester_registration?.course_status != 8 &&
              !item?.course_semester_registration?.request_type
          );
        set_general_eduction(con_array);
      }

      set_basic_information(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.basic_information
      );
      set_students_programmes_registrations(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.students_programmes_registrations
      );
      set_semester_registration(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.semester_registration
      );
      set_semester_name(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.semester?.title
      );
      set_calendar(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.calendar
      );
      set_program(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.program
      );
      set_transferred_course_list(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.transferred_course_list
      );
      set_loader(false);
    } else {
      set_loader(false);
    }
  };

  const get_timing = (data, selected_days) => {
    return (
      <>
        {selected_days.map((item) => (
          <>
            {item?.start_time && (
              <>
                {data?.timing ? (
                  <>
                    <span
                      style={{
                        width: "110px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "12px",
                      }}
                    >
                      {item?.value?.slice(0, 2).toUpperCase()}{" "}
                      {item?.start_time} - {item?.end_time}
                    </span>
                    <br />
                  </>
                ) : (
                  <>
                    <span style={{ color: "red" }}>Not Available</span>
                  </>
                )}
              </>
            )}
          </>
        ))}
      </>
    );
  };

  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>
          <div>
            <h3
              className="first-letter-capitalize"
              style={{ color: "#999", padding: "20px 0" }}
            >
              My Academic Details
            </h3>
          </div>
          <div className="row">
            <div
              className="col-3 "
              style={{ display: "flex", flexDirection: "column" }}
            >
              <p className="first-letter-capitalize">Student Type</p>
              <h3>
                {students_programmes_registrations?.student_type
                  ? students_programmes_registrations?.student_type
                  : "N/A"}
              </h3>
            </div>
            <div
              className="col-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <p className="first-letter-capitalize">Matriculation Semester</p>
              <h3>{semester_name ? semester_name : "N/A"}</h3>
            </div>
            <div
              className="col-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <p className="first-letter-capitalize">School Decision</p>
              <h3>
                {basic_information?.school_decision
                  ? basic_information?.school_decision
                  : "N/A"}
              </h3>
            </div>
            <div
              className="col-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <p className="first-letter-capitalize">Entering Classification</p>
              <h3>
                {students_programmes_registrations?.entering_classification
                  ? students_programmes_registrations?.entering_classification
                  : "N/A"}
              </h3>
            </div>
          </div>
          <br />
          <div className="row">
            <div
              className="col-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <p className="first-letter-capitalize">Academic Year</p>
              <h3>{calendar?.title ? calendar?.title : "N/A"}</h3>
            </div>
            <div
              className="col-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <p className="first-letter-capitalize">Program/ Degree</p>
              <h3>{program?.title ? program?.title : "N/A"}</h3>
            </div>
            <div
              className="col-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <p className="first-letter-capitalize">Concentration</p>
              <h3>
                {selected_concentration?.title
                  ? selected_concentration?.title
                  : "N/A"}
              </h3>
            </div>
            <div
              className="col-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <p className="first-letter-capitalize">Program Status</p>
              <h3>
                {students_programmes_registrations?.programme_status
                  ? students_programmes_registrations?.programme_status
                  : "N/A"}
              </h3>
            </div>
          </div>
          <br />
          <hr />
          {transferred_course_list.length > 0 && (
            <AcademicsTransferredCourseTable
              transferred_course_list={transferred_course_list}
            />
          )}
          <div>
            <h3
              className="first-letter-capitalize"
              style={{ color: "#999", padding: "20px 0" }}
            >
              Attendance Summary
            </h3>
          </div>
          <div className="first-letter-capitalize" style={{ color: "#999" }}>
            For the
          </div>
          <h4 style={{ color: "#777" }}>
            {attendances_summary?.calendar_title}
          </h4>
          <br></br>
          <div className="attendance-summary">
            <div className="attendance-item">
              <div className="attendance-label first-letter-capitalize">
                Absent unexcused
              </div>
              <div className="attendance-value">
                {attendances_summary?.absent_unexcused}
              </div>
            </div>
            <div className="attendance-item">
              <div className="attendance-label first-letter-capitalize">
                Absent Excused
              </div>
              <div className="attendance-value">
                {attendances_summary?.absent_excused}
              </div>
            </div>
            <div className="attendance-item">
              <div className="attendance-label first-letter-capitalize">
                Tardy Excused
              </div>
              <div className="attendance-value">
                {attendances_summary?.tardy_excused}
              </div>
            </div>
          </div>
          <br></br>
          <h3 className="first-letter-capitalize" style={{ color: "#8E8E8E" }}>
            Program Progress
          </h3>
          <br></br>
          <div className="programme-attendance-summary">
            <div className="programme-attendance-item">
              <div className="programme-attendance-label first-letter-capitalize">
                Credit Earned
              </div>
              <div className="programme-attendance-value ">
                {programme_progress?.credit_earned}
              </div>
            </div>
            <div className="programme-attendance-item">
              <div className="programme-attendance-label first-letter-capitalize">
                In Progress
              </div>
              <div className="attendance-value">
                {programme_progress?.in_progress}
              </div>
            </div>
            <div className="programme-attendance-item">
              <div className="programme-attendance-label first-letter-capitalize">
                Credit Remaining
              </div>
              <div className="programme-attendance-value">
                {programme_progress?.credit_remain}
              </div>
            </div>
            <div className="programme-attendance-item">
              <div className="programme-attendance-label first-letter-capitalize">
                Total Credit
              </div>
              <div className="programme-attendance-value">
                {programme_progress?.table_credit}
              </div>
            </div>
          </div>
          {general_eduction?.length > 0 ? (
            <div
              style={{
                border: "1px solid #E1E1E1",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <div
                className="container"
                style={{ color: "#3B82F6", padding: "10px 0" }}
              >
                <div className="col-6 first-letter-capitalize">
                  <h3
                    className="first-letter-capitalize"
                    style={{ color: "#3B82F6" }}
                  >
                    General Education
                  </h3>
                </div>
                {/* <div className="col-6">
                      {programme_progress?.in_progress}
                    </div> */}
              </div>
              <div>
                <Table
                  columns={columns}
                  pagination={false}
                  dataSource={general_eduction}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          <br />
          {progrma_concentration?.length > 0 ? (
            <div
              style={{
                border: "1px solid #E1E1E1",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <div
                className="container"
                style={{ color: "#3B82F6", padding: "10px 0" }}
              >
                <div className="col-6 first-letter-capitalize">
                  <h3
                    className="first-letter-capitalize"
                    style={{ color: "#3B82F6" }}
                  >
                    Concentration{" "}
                  </h3>
                  <h3
                    className="first-letter-capitalize"
                    style={{ color: "#4b4b4b" }}
                  >
                    {selected_concentration?.title
                      ? selected_concentration?.title
                      : "N/A"}
                  </h3>
                </div>
                {/* <div className="col-6">
                      {programme_progress?.in_progress}
                    </div> */}
              </div>
              <div>
                  <Table
                    columns={columns}
                    pagination={false}
                    dataSource={progrma_concentration}
                  />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default ViewStudentAcademics;
