


import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import SisLoader from '../../../../widgets/loader';
import { Button, Input, notification, Result, Spin } from 'antd';
import { LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import { CountryDropdown } from 'react-country-region-selector';
import { ADD_COURSE_INDENT_CHECKOUT_API } from '../../../../apis/apis';

const AddCourseCheckoutForm = ({set_loader, payment_indent_json,}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [name, set_name] = useState("");
    const [loaderCoupon, set_loaderCoupon] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submit_loading, set_submit_loading] = useState(false);
    const [card_error, set_card_error] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [apply_coupon, set_apply_coupon] = useState(true);
    const [remove_coupon, set_remove_coupon] = useState(false);
    const [coupon, setCoupon] = useState(null);
    const [isCardFocused, setIsCardFocused] = useState(false);
    const [amount, set_amount] = useState('');
    const [payment_intent, set_payment_intent] = useState('');
    const [payment_amount, set_payment_amount] = useState('');
    const [payment_status, set_payment_status] = useState('');
    const [payment_row, set_payment_row] = useState('');
    const [response_json, set_response_json] = useState('');
    const [address1, set_address1] = useState("");
    const [address2, set_address2] = useState("");
    const [address_city, set_address_city] = useState("");
    const [address_state, set_address_state] = useState("");
    const [address_postal, set_address_postal] = useState("");
    const [address_country, set_address_country] = useState("");
  
    const [indent_json, set_indent_json] = useState("");
    const navigate = useNavigate();
    const [errors, set_errors] = useState({});
    useEffect(() => {
        set_indent_json(JSON?.parse(payment_indent_json));
     },[])
   
     const validateFields = () => {
        let errors = {};
        if (!name) errors.name = "Please Enter Full Name";
        if (!address1) errors.address1 = "Please Enter Address";
        if (!address_city) errors.address_city = "Please Enter City";
        if (!address_state) errors.address_state = "Please Enter State";
        if (!address_postal) errors.address_postal = "Please Enter Zip/Postal Code";
        if (!address_country) errors.address_country = "Please Enter Country";
    
        set_errors(errors);
        return Object.keys(errors).length === 0;
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateFields()) {
          set_submit_loading(true);
          set_card_error("");
    
        if (!stripe || !elements) {
          return;
        }
    
        const cardElement = elements.getElement(CardElement);
    
        const clientSecret = indent_json?.client_secret;
    
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: name,
            address: {
              line1: address1,
              line2: address2,
              city: address_city,
              state: address_state,
              postal_code: address_postal,
              country: address_country,
            },
          },
        });
    
        if (error) {
          set_card_error(error.message)
          console.log('[error]', error);
        } else {
          set_card_error("")
    
          console.log('[PaymentMethod]', paymentMethod);
    
          // After successfully creating the payment method, you typically confirm the payment using the clientSecret
          const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethod.id,
          });
    
          if (confirmError) {
           // console.log('[confirmError]', confirmError);
          } else {
           if(paymentIntent.status === 'succeeded')
            {
              CALL_ADD_COURSE_INDENT_CHECKOUT_API();
               // here we call checkout api.
            }
            //console.log('[PaymentIntent]', paymentIntent);
            // Apply discount logic or send it to the server for processing
          }
        }
      }
      };

      const cardElementOptions = {
        hidePostalCode: true,
        style: {
          base: {
            fontSize: '16px',
            color: '#000000',
            '::placeholder': {
              color: '#000000',
            },
            border: '1px solid #000000',
            padding: '10px',
          },
          invalid: {
            color: '#9e2146',
          },
        },
      };

      const CALL_ADD_COURSE_INDENT_CHECKOUT_API = async () => {
        setLoading(true);
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA?.token);
        FORM_DATA.append('payment_intent_id', indent_json?.id);
        FORM_DATA.append('student_user_id', USER_DATA?.id);
        const RESPONSE = await ADD_COURSE_INDENT_CHECKOUT_API(FORM_DATA);
        if (RESPONSE?.data?.status) {
          notification.open({
            message: 'Success!!',
            description: 'Your add course amount successfully received.',
            icon: <SmileOutlined style={{ color: 'green' }} />,
        });
          window.location = '/students-academic-detail';
          //navigate('/students-academic-detail')
          setLoading(false);
        }
    
        setLoading(false);
      }
    




     // Show loader until data is fetched
  if (loading) {
    return <SisLoader />;
  }

  return (
    <>
      {payment_status ?
        <>
          <div style={{ textAlign: 'center' }}>
            <Result
              icon={<SmileOutlined />}
              title="Thank you for your payment!"
              subTitle={`Your payment for the
         program has been successfully processed.`}
              extra={
                <>
                  <Button type='primary' onClick={() => window.open(response_json?.receipt_url)} ghost>Open Receipt</Button>
                </>
              }
            />
            {/* <div style={{ marginTop: '20px' }}>
        <p>Enrollment ID: {}</p>
        <p>You will receive a confirmation email shortly with the details of your enrollment.</p>
      </div> */}
          </div>
          <br/>
        </>
        :
        <>
          <form onSubmit={handleSubmit}>
            <div style={{ margin: "0 10px" }}>
              <label className="cus-label" style={{ textTransform: "none", fontSize: "14px" }}>
                Credit Card or Debit Card<i style={{ color: "red" }}>*</i>
              </label>
              <div className='row' style={{ margin: "10px 10px 0 0" }}>
                <div className='col-12'
                  style={{
                    border: isCardFocused ? '1px solid #dfa414' : '1px solid #ced4da',
                    borderRadius: '4px',
                    padding: '10px',
                  }}
                >
                  <CardElement
                    onFocus={() => setIsCardFocused(true)}
                    onBlur={() => setIsCardFocused(false)}
                    options={cardElementOptions} />
                </div>
                {card_error &&
                  <span style={{ color: "#F00" }}>{card_error}</span>
                }
              </div>
            </div>
            <br />
            <div style={{ margin: "0 10px" }}>
              <label className="cus-label" style={{ textTransform: "none", fontSize: "14px" }}>
                Name on Card<i style={{ color: "red" }}>*</i>
              </label>
              <div className='row' style={{ margin: "0px 10px 0 0" }}>
                <div className="col-12" style={{ padding: 0 }}>
                  <div className="input-box">
                    <Input
                      disabled={localStorage.getItem("payment_status") == 1}
                      value={name}
                      placeholder="Enter Your Name"
                      onChange={(e) => set_name(e.target.value)}
                    />
                     {errors.name && <div style={{ color: "red" }}>{errors.name}</div>}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div style={{ margin: "0 10px" }}>
              <label className="cus-label" style={{ textTransform: "none", fontSize: "14px" }}>
                Address<i style={{ color: "red" }}>*</i>
              </label>
              </div>
            <div className="row" style={{ margin: "0px 10px 0 0" }}>
              <div className="col-12">
                <div className="input-box">
                  <label htmlFor="address1">
                   Address 1
                  </label>
                  <Input
                    value={address1}
                    placeholder="Address Line 1"
                    onChange={(e) => set_address1(e.target.value)}
                  />
                   {errors.address1 && <div style={{ color: "red" }}>{errors.address1}</div>}
                </div>
              </div>
            </div>
            <div className="row" style={{ margin: "0px 10px 0 0" }}>
            <div className="col-12">
                <div className="input-box">
                  <label htmlFor="address2">
                   Address 2
                  </label>
                  <Input
                    value={address2}
                    placeholder="Address Line 2"
                    onChange={(e) => set_address2(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row" style={{ margin: "0px 10px 0 0" }}>
              <div className="col-6">
                <div className="input-box">
                  <label htmlFor="city">
                    City
                  </label>
                  <Input
                    value={address_city}
                    placeholder="City"
                    onChange={(e) => set_address_city(e.target.value)}
                  />
                   {errors.address_city && <div style={{ color: "red" }}>{errors.address_city}</div>}
                </div>
              </div>
              <div className="col-6">
                <div className="input-box">
                  <label htmlFor="state">
                    State
                  </label>
                  <Input
                    value={address_state}
                    placeholder="State"
                    onChange={(e) => set_address_state(e.target.value)}
                  />
                  {errors.address_state && <div style={{ color: "red" }}>{errors.address_state}</div>}
                </div>
              </div>
              </div>
              <div className="row" style={{ margin: "0px 10px 0 0" }}>
              <div className="col-6">
                <div className="input-box">
                  <label htmlFor="postal">
                    Zip / Postal Code
                  </label>
                  <Input
                    value={address_postal}
                    placeholder="Zip/Postal"
                    onChange={(e) =>
                      set_address_postal(e.target.value)
                    }
                    onKeyDown={(event) => {
                      // Allow Backspace, Delete, Tab, Enter, Arrow keys
                      if (
                        event.key === 'Backspace' ||
                        event.key === 'Delete' ||
                        event.key === 'Tab' ||
                        event.key === 'Enter' ||
                        event.key === 'ArrowUp' ||
                        event.key === 'ArrowDown' ||
                        event.key === 'ArrowLeft' ||
                        event.key === 'ArrowRight'
                      ) {
                        return;
                      }

                      // Prevent non-numeric characters
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                   {errors.address_postal && <div style={{ color: "red" }}>{errors.address_postal}</div>}
                </div>
              </div>
               <div className="col-6">
                <div className="input-box">
                  <label htmlFor="country">
                    Country
                  </label>
                  <CountryDropdown
                    valueType="short"
                    id="mailingcountry"
                    style={{ width: "100%", height: "40px" }}
                    placeholde="--Select Country--"
                    value={address_country}
                    onChange={(value) => set_address_country(value)}
                    className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box"
                  />
                  {errors.address_country && <div style={{ color: "red" }}>{errors.address_country}</div>}
                </div>
              </div>
            </div>
            <div className="row"  style={{ margin: "0px 10px 0 0" }}>
              <div className="col-12" style={{ marginRight: "15px" }}>
                <div className="input-box">
                  {submit_loading || !stripe ? (
                    <>
                      <Button type="primary" style={{width:"100%"}}>
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: "12px",
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            />
                          }
                        />{" "}
                        Pay
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button type="primary" htmlType="submit" style={{width:"100%"}}>
                        Pay{" "}${" "}{(indent_json?.amount /100).toFixed(2)}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </form>
        </>
      }
    </>
  )
}

export default AddCourseCheckoutForm;