import { Button, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SelfCourseRegistration from "./selfCourseRegistration";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING } from "../../apis/apis";
import SisLoader from "../../widgets/loader";
import { unit_selection_limit } from "../common-functions";

const SelfSemesterRegistration = () => {
  const { sem_id } = useParams();

  const navigate = useNavigate();
  const [submit_button_status, set_submit_button_status] = useState(true);
  const [submit_button_status_message, set_submit_button_status_message] = useState('');
  const [loader, set_loader] = useState(true);
  const [errors, set_errors] = useState([]);
  const [semester_name, set_semester_name] = useState("");
  const [registration_no, set_registration_no] = useState("");
  const [financial_aid, set_financial_aid] = useState("");
  const [sct_status, set_sct_status] = useState("");
  const [list_api_refresh, set_list_api_refresh] = useState(" ");
  const [general_eduction_list, set_general_eduction_list] = useState([]);
  const [progrma_concentration_list, set_progrma_concentration_list] = useState(
    []
  );
  const [
    student_semester_registration_course_listing,
    set_student_semester_registration_course_listing,
  ] = useState([]);
  const [admission_status, set_admission_status] = useState("");
  const [student_category, set_student_category] = useState("");
  const [entering_classification, set_entering_classification] = useState("");
  const [basic_information, set_basic_information] = useState("");
  const [semester_registration, set_semester_registration] = useState("");
  const [students_programmes_registrations, set_students_programmes_registrations] = useState("");

  useEffect(() => {
    VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING();
  }, []);

  useEffect(() => {
    VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING2();
  }, [list_api_refresh]);

  const VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", USER_DATA?.id);
    FORM_DATA.append("token", USER_DATA?.token);
    FORM_DATA.append("acad_calender_id", parseInt(USER_DATA?.calendar_id));
    FORM_DATA.append("semester_id", parseInt(USER_DATA?.current_semester));
    const VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE =
      await STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING(FORM_DATA);
    if (
      VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
        ?.status
    ) {
      set_loader(false);
      set_student_semester_registration_course_listing(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
      );
      set_semester_name(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.semester?.title
      );
      set_registration_no(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.semester_registration?.registration_no
      );
      set_financial_aid(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.semester_registration?.financial_aid
      );
      set_sct_status(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.semester_registration?.sct_status
      );
      set_general_eduction_list(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.general_eduction
      );
      set_progrma_concentration_list(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.progrma_concentration
      );
      set_admission_status(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.semester_registration?.admission_status
      );
      set_entering_classification(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.students_programmes_registrations?.entering_classification
      );
      set_student_category(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.basic_information?.student_category
      );
      set_basic_information(VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.basic_information);
      set_students_programmes_registrations(VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
        ?.students_programmes_registrations);
      set_semester_registration(VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.semester_registration);

      if(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.semester_registration?.admission_status &&  VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.semester_registration?.sct_status)
        {
          const Button_Satus = unit_selection_limit(VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.student_selected_units,
            VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.semester_registration,
            VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.basic_information, VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.students_programmes_registrations, VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.program,);
          set_submit_button_status_message(Button_Satus?.message ? Button_Satus?.message : '')
          set_submit_button_status(Button_Satus?.status ? false : true);
        }
    } else {
      set_loader(false);
    }
  };

  const VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING2 = async () => {
    // set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", USER_DATA?.id);
    FORM_DATA.append("token", USER_DATA?.token);
    FORM_DATA.append("acad_calender_id", parseInt(USER_DATA?.calendar_id));
    FORM_DATA.append("semester_id", parseInt(USER_DATA?.current_semester));
    const VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE =
      await STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING(FORM_DATA);
    if (
      VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
        ?.status
    ) {
      // set_loader(false);
      set_student_semester_registration_course_listing(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
      );
      set_semester_name(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.semester?.title
      );
      set_registration_no(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.semester_registration?.registration_no
      );
      set_financial_aid(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.semester_registration?.financial_aid
      );
      set_general_eduction_list(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.general_eduction
      );
      set_progrma_concentration_list(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.progrma_concentration
      );
      set_admission_status(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.semester_registration?.admission_status
      );
      set_entering_classification(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.students_programmes_registrations?.entering_classification
      );
      set_student_category(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
          ?.basic_information?.student_category
      );
      set_basic_information(VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.basic_information);
      set_students_programmes_registrations(VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
        ?.students_programmes_registrations);
      set_semester_registration(VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.semester_registration);

      if(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.semester_registration?.admission_status &&  VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.semester_registration?.sct_status)
        {
          const Button_Satus = unit_selection_limit(VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.student_selected_units,
            VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.semester_registration,
            VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.basic_information, VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.students_programmes_registrations, VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.program,);
          set_submit_button_status_message(Button_Satus?.message ? Button_Satus?.message : '')
          set_submit_button_status(Button_Satus?.status ? false : true);
        }

     
    } else {
      // set_loader(false);
    }
  };
  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>
        {registration_no && admission_status && sct_status ?
        <>
          <div>
            <h2
              className="first-letter-capitalize"
              style={{ color: "#666", padding: "20px 0" }}
            >
              {semester_name}
            </h2>
          </div>
          <div>
            <hr />
            <br />
            <div className="theme-content-left-head">
              <h3>Registration Details</h3>
            </div>
            <br />
          </div>
          <div className="row">
            <div className="col-3">
              <div className="input-box">
                <label>Semester</label>
                <h4>{semester_name}</h4>
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label>Registration Number</label>
                <h4>{registration_no ? registration_no : "-"}</h4>
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label>Financial Aid</label>
                <h4>{financial_aid != "null" ? financial_aid : "-"}</h4>
              </div>
            </div>
          </div>
          <br />
          {(general_eduction_list?.length === 0 && progrma_concentration_list?.length === 0) ? 
          <>
          <br/>
          <p style={{color:"red" , textAlign:"center"}}>No Courses Schedule in this Semester Yet.</p>
          </>
           : 
           <>
            <div>
            <div className="theme-content-left-head">
              <h3>Course Selection</h3>
              {submit_button_status &&  <>
                <h4 style={{ marginTop: "10px", color: "red" }}>
                <InfoCircleOutlined
                  style={{ marginRight: "10px", fontSize: "16px" }}
                />
                 {submit_button_status_message}
              </h4>
              </> }
             
            </div>
            <br />
          </div>
          {(general_eduction_list?.length > 0 ||
            progrma_concentration_list?.length > 0) && (
              <>
                <SelfCourseRegistration
                  set_list_api_refresh={set_list_api_refresh}
                  student_semester_registration_course_listing={
                    student_semester_registration_course_listing
                  }
                />
              </>
            )}
          <br />
          <div className="row">
            <div className="col-4" style={{ width: "200px" }}>
              <div className="input-box">
                {loader ? (
                  <>
                    <Button type="primary">
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: "12px",
                              color: "#fff",
                              marginRight: "5px",
                            }}
                          />
                        }
                      />{" "}
                      Submiting
                    </Button>
                  </>
                ) : (
                  <>
                    <Button onClick={() => navigate("/students-academic-detail")} type="primary"
                      disabled={submit_button_status}>
                      Submit
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
           </> }
        </>
        :
        <div style={{display : "flex", justifyContent:"center", alignItems:"center"}}>
        <p style={{color:"red", textAlign:"center"}}>You are currently not Registered in this Semster.</p>
        </div>
}
        </>
      )}
    </>
  );
};

export default SelfSemesterRegistration;
