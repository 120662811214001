import { Select, Radio, Input, Button, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GET_FINANCE_DECLARATION, SIS_STORAGE } from "../../../../apis/apis";
import { CloseOutlined, CloudUploadOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { CountryDropdown } from "react-country-region-selector";
import SisLoader from "../../../../widgets/loader";


const FinanceDeclarationPreview = () => {

    const [fees_payee, set_fees_payee] = useState('')
    const [fund_source, set_fund_source] = useState('')
    const [source_name, set_source_name] = useState('')
    const [source_statement, set_source_statement] = useState("");
    const [api_source_statement, api_set_source_statement] = useState("");
    const [sponsor_first_name, set_sponsor_first_name] = useState("");
    const [sponsor_middle_name, set_sponsor_middle_name] = useState("");
    const [sponsor_last_name, set_sponsor_last_name] = useState("");
    const [sponsor_relationship, set_sponsor_relationship] = useState("");
    const [sponsor_citizenship, set_sponsor_citizenship] = useState("");
    const [sponsor_address1, set_sponsor_address1] = useState("");
    const [sponsor_address2, set_sponsor_address2] = useState("");
    const [sponsor_address_city, set_sponsor_address_city] = useState("");
    const [sponsor_address_state, set_sponsor_address_state] = useState("");
    const [sponsor_address_postal, set_sponsor_address_postal] = useState("");
    const [sponsor_address_country, set_sponsor_address_country] = useState("");
    const [sponsor_phone, set_sponsor_phone] = useState("");
    const [phoneerror, set_Phone_Error] = useState('');
    const [sponsor_email, set_sponsor_email] = useState("");
    const [loader, set_loader] = useState(true);
    const [errors, set_errors] = useState([]);

    const navigate = useNavigate();

    const sourceStatementImage = useRef(null);

    useEffect(() => {
        VIEW_FINANCE_DECLARATION();
    }, []);



    const VIEW_FINANCE_DECLARATION = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('student_user_id', USER_DATA.id);
        const VIEW_FINANCE_DECLARATION_API_RESPONSE = await GET_FINANCE_DECLARATION(FORM_DATA);
        if (VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.status) {
            set_loader(false);
            set_fees_payee((VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.fees_payee)=== "undefined" ? "" : VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.fees_payee);
            set_fund_source(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.fund_source)
            set_source_name(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.source_name);
            api_set_source_statement(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.source_statement);
            set_sponsor_first_name(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_first_name);
            set_sponsor_middle_name(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_middle_name);
            set_sponsor_last_name(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_last_name);
            set_sponsor_relationship(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_relationship);
            set_sponsor_citizenship(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_citizenship);
            set_sponsor_address1(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_address1);
            set_sponsor_address2(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_address2);
            set_sponsor_address_city(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_address_city);
            set_sponsor_address_state(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_address_state);
            set_sponsor_address_postal(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_address_postal);
            set_sponsor_address_country(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_address_country);
            set_sponsor_phone(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_phone);
            set_sponsor_email(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_email);
        }
        else {
            set_loader(false);
        }
    }


    return (
        <>
        {loader? <SisLoader/>
        :
        <>
            <div>
                <br />
                <hr />
                {localStorage.getItem("payment_status") == 0 &&
                <div className="row">
                    <div className="col-2" style={{ float: "right" }}>
                        <div className="input-box" style={{ float: "right" }}>
                            <Button
                             className="lusisbtn"
                                type="primary"
                                icon={<EditOutlined />}
                                onClick={() => navigate("/finance-declaration")}>
                                Edit
                            </Button>
                        </div>
                    </div>
                </div>
                }
                <div className="theme-content-left-head">
                    <h2 style={{ color: "#626262" }}>Applicant's Declaration of Finances</h2>
                </div>
                <br />
            </div>
            <div className="row">
                <div className="col-5  first-letter-capitalize" style={{ textTransform: "none" }}>
                    <p>Who will pay for your educational expenses at Lincoln University?</p>
                    <h4 style={{ marginTop: "6px" }}>{fees_payee}</h4>
                </div>
            </div>
            <br/>

            {fees_payee == "Myself" &&
                <>
                    <div className="row">
                        <div className="col-10 first-letter-capitalize" style={{ textTransform: "none" }}>
                            <p> Source of Funds</p>
                            <h4 style={{ marginTop: "6px" }}>
                                {fund_source === "bank" &&
                                    "Bank: Please upload below a bank statement verifying that these funds are deposited in bank on my name and available for my educational use."
                                }
                                {fund_source === "financial institution" &&
                                    "Financial Institution: Please upload below a letter/statement verifying that the funds will be made available for my educational use in the U.S.A."
                                }
                            </h4>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-6 first-letter-capitalize" style={{ textTransform: "none" }}>
                            <p>Name of Bank/Financial Institution</p>
                            <h4 style={{ marginTop: "6px" }}>{source_name}</h4>
                        </div>
                    </div>
                </>
            }
            <br/>
            <div className="row">
                <div className="col-6" style={{ position: "relative",textTransform: "none" }}>
                    <h4 className="first-letter-capitalize" style={{textTransform:"none"}}>Please Upload Bank Statement or Financial Institution Letter/Statement</h4>
                    <ul className="document-list">
                        <li
                            onClick={() =>
                                window.open(
                                    SIS_STORAGE +
                                    "/documents/" +
                                    api_source_statement
                                )
                            }  >
                            {api_source_statement}
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
            {fees_payee == "Sponsor" &&
                <>
                    <div>
                        <br />
                        <hr />
                        <br />
                        <div className="theme-content-left-head">
                            <h3>Sponsor's Information</h3>
                        </div>
                        <br />
                    </div>
                    <div className="row">
                        <div className="col-4 first-letter-capitalize">
                            <p>First Name</p>
                            <h4 style={{ marginTop: "6px" }}>{sponsor_first_name}</h4>
                        </div>
                        {/* Middle Name */}
                        <div className="col-4 first-letter-capitalize">
                            <p>Middle Name</p>
                            <h4 style={{ marginTop: "6px" }}>{sponsor_middle_name}</h4>
                        </div>
                        {/* Last Name */}
                        <div className="col-4 first-letter-capitalize">
                            <p>Last Name</p>
                            <h4 style={{ marginTop: "6px" }}>{sponsor_last_name}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 first-letter-capitalize" style={{ textTransform: "none" }}>
                            <p>Sponsor's Relationship to Student</p>
                            <h4 style={{ marginTop: "6px" }}>{sponsor_relationship}</h4>
                        </div>
                        {/* Citizenship */}
                        <div className="col-4 first-letter-capitalize" style={{ textTransform: "none" }}>
                            <p> Sponsor's Country of Citizenship</p>
                            <h4 style={{ marginTop: "6px" }}>{sponsor_citizenship}</h4>
                        </div>
                    </div>
                    <br />
                    <div className="theme-content-left-head">
                        <h3 style={{ color: "#626262" }}>Sponsor's Address</h3>
                    </div>
                    <br />
                    <div className="row">
                        <div className="first-letter-capitalize">
                            <h4 style={{ marginTop: "6px" }}>{sponsor_address1},{" "}{(sponsor_address2) === "null" ? "" : sponsor_address2},{" "}
                                {sponsor_address_city},{" "}{sponsor_address_state},{" "}{sponsor_address_country},{" "}{sponsor_address_postal}.
                            </h4>
                        </div>
                    </div>
                    <br />
                    <div className="theme-content-left-head">
                        <h3 style={{ color: "#626262" }}>Sponsor's Contact Details</h3>
                    </div>
                    <br />
                    <div className="row">
                        {/* Phone Number */}
                        <div className="col-4 first-letter-capitalize">
                            <p>Sponsor's Phone</p>
                            <h4 style={{ marginTop: "6px" }}>{sponsor_phone}</h4>
                        </div>
                        {/* sponsor Email */}
                        <div className="col-4 first-letter-capitalize">
                            <p>Sponsor's Phone</p>
                            <h4 style={{ marginTop: "6px" }}>{sponsor_email}</h4>
                        </div>
                    </div>
                </>
            }
            </>
        }
        </>
    );
}

export default FinanceDeclarationPreview;