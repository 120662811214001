import React from "react";

const AddDropCourseViewBilling = () => {
  return (
    <>
      <div className="theme-content-head" style={{ marginBottom: "0px" }}>
        <div className="theme-content-left-head">
          <h3 style={{ marginTop: "10px" }}>Add/Drop Fees Payment</h3>
        </div>
      </div>
      <br />
      <hr />
      <div className="Total_Due" style={{ marginTop: "0" }}>
        <div className="billing_row">
          <span>Add/Drop Fees</span>
          {/* <span>{total_credit_fee}</span> */}
        </div>
        <hr />
        <div className="billing_row">
          <h4>Total amount to be paid</h4>
          {/* <h4>$ {isNaN(sum_special_fees) ? 0 : sum_special_fees}</h4> */}
        </div>
      </div>
    </>
  );
};

export default AddDropCourseViewBilling;
