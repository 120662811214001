import Concentration from "./Select-Courses/concentration";
import GeneralEducation from "./Select-Courses/generalEducation";


const SelfCourseRegistration = ({student_semester_registration_course_listing, set_list_api_refresh}) => {
      
    return (
        <>
            <div>
                <div className="theme-content-left-head">
                    <h4>Units selected for this semester : {student_semester_registration_course_listing?.student_selected_units}</h4><br/>
                    <h4>Given Credit for this semester : {student_semester_registration_course_listing?.student_given_units}</h4>
                </div>
                <br />
            </div>
            {student_semester_registration_course_listing?.general_eduction?.length > 0 &&
            <GeneralEducation student_semester_registration_course_listing={student_semester_registration_course_listing} set_list_api_refresh={set_list_api_refresh} />
            }
            <br/>
            {student_semester_registration_course_listing?.progrma_concentration?.length > 0 &&
            <Concentration student_semester_registration_course_listing={student_semester_registration_course_listing} set_list_api_refresh={set_list_api_refresh} />
            }
        </>
    )
}

export default SelfCourseRegistration;